// assets
import { IconDashboard, IconList, IconRobot, IconTable, IconCubeSend, IconEdit } from '@tabler/icons';
import {UnarchiveOutlined, UnarchiveRounded} from "@mui/icons-material";

// constant
const icons = { IconDashboard, IconList, IconRobot, IconTable, IconCubeSend, IconEdit };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        // {
        //     id: 'default',
        //     title: 'Dashboard',
        //     type: 'item',
        //     url: '/dashboard/default',
        //     icon: icons.IconDashboard,
        //     breadcrumbs: false
        // },
        // {
        {
            id: 'events',
            title: 'Events y activitats',
            type: 'item',
            url: '/dashboard/events',
            icon: icons.IconTable,
            breadcrumbs: false
        },
        {
            id: 'my-events',
            title: 'Les meues entrades',
            type: 'item',
            url: '/dashboard/els-meus-events',
            icon: icons.IconEdit,
            breadcrumbs: false
        },
    ]
};

export default dashboard;
