import {lazy} from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const NotFound = Loadable(lazy(() => import('views/pages/not-found')));
const EventsScreen = Loadable(lazy(() => import('views/pages/events')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {

        path: '/',
        element: <MainLayout/>,
        children: [
            {
                path: '*',
                element: <NotFound/>
            },
            {
                path: 'dashboard',
                children: [
                    // {
                    //     path: 'default',
                    //     element: <DashboardDefault/>
                    // },
                    {
                        path: 'events',
                        element: <EventsScreen/>
                    },
                ]
            },
        ]
};

export default MainRoutes;
