import dashboard from './dashboard';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [dashboard, pages, utilities, other]
    items: [dashboard]
};

export default menuItems;
